import type { ComponentPropsWithRef } from 'react'

export default function LoungePairLogo(
  props: ComponentPropsWithRef<'svg'> & { alt?: string },
) {
  const { className, alt = 'LoungePair Logo' } = props
  return (
    <svg
      id="logo"
      className={className || ''}
      width="319"
      height="67"
      viewBox="0 0 319 67"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>{alt}</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 12.4342C0 5.56699 5.56699 0 12.4342 0C19.3014 0 24.8684 5.56699 24.8684 12.4342V31.5C24.8684 33.7891 23.0128 35.6447 20.7237 35.6447C18.4346 35.6447 16.5789 33.7891 16.5789 31.5V10.7763C16.5789 8.48724 14.7233 6.63158 12.4342 6.63158C10.1451 6.63158 8.28947 8.48724 8.28947 10.7763C8.28965 17.7191 8.28947 24.662 8.28947 31.6048C8.28947 37.0788 12.5533 41.4474 17.6151 41.4474C24.1776 41.4474 30.7401 41.4474 37.3026 41.4474C44.1699 41.4474 49.7368 47.0144 49.7368 53.8816C49.7368 60.7488 44.1699 66.3158 37.3026 66.3158C30.7401 66.3154 24.1776 66.3158 17.6151 66.3158C9.23079 66.3158 2.32638 60.1391 0.483497 52.3241C0.197823 51.1127 1.78177 50.5397 2.65225 51.4293C6.54362 55.4063 12.1345 58.0178 17.5943 58.0263C24.7164 58.0349 31.8385 58.0259 38.9605 58.0263C41.2496 58.0263 43.1053 56.1707 43.1053 53.8816C43.1053 51.5925 41.2496 49.7368 38.9605 49.7368L17.4079 49.7356C7.68841 49.6211 0 41.4582 0 31.6048C0 25.2146 0 18.8244 0 12.4342Z"
        fill="#316BFF"
      />
      <path
        d="M315.11 21.285C316.687 21.285 317.838 21.5408 318.819 22.0097L317.284 27.7223C316.175 27.1255 314.982 26.8271 313.49 26.8271C310.761 26.8271 308.928 29.0439 308.928 33.0513V45.4997H302.704V21.626H308.885V27.0402C309.951 23.1181 311.742 21.285 315.11 21.285Z"
        fill="#05203C"
      />
      <path
        d="M289.617 14.2507C289.617 11.906 290.981 10.286 293.539 10.286C295.969 10.286 297.419 11.906 297.419 14.2507C297.419 16.5102 295.969 18.0876 293.539 18.0876C290.981 18.0876 289.617 16.5102 289.617 14.2507ZM290.257 21.626H296.524V45.4997H290.257V21.626Z"
        fill="#05203C"
      />
      <path
        d="M260.58 39.9151C260.58 37.1866 261.774 35.6093 263.522 34.4582C265.227 33.3498 267.529 32.7103 272.176 31.7724C276.141 30.9624 276.866 30.1951 276.866 28.7882C276.866 27.2535 275.672 26.0172 272.603 26.0172C269.49 26.0172 267.615 27.7224 266.762 30.4509L261.135 28.8309C263.138 23.5872 267.615 21.1572 272.901 21.1572C278.869 21.1572 282.791 24.0561 282.791 30.1524V38.3377C282.791 40.5545 283.431 41.194 285.008 41.194C285.648 41.194 286.202 41.0235 286.671 40.853V44.8603C285.818 45.4145 284.369 45.9261 282.578 45.9261C278.741 45.9261 277.121 44.2209 276.908 40.5119C275.416 43.9651 272.176 45.9261 268.126 45.9261C263.522 45.9261 260.58 43.7093 260.58 39.9151ZM277.036 34.8845V33.4351C276.439 34.1172 275.16 34.5861 272.645 35.1403C268.467 36.0356 266.847 36.7603 266.847 38.8066C266.847 40.5545 268.126 41.3645 270.556 41.3645C274.862 41.3645 276.951 37.4851 277.036 34.8845Z"
        fill="#05203C"
      />
      <path
        d="M245.405 36.3767H238.115V45.4998H231.848V14.3788H244.936C253.42 14.3788 257.811 18.6419 257.811 25.1219C257.811 32.1135 252.781 36.3767 245.405 36.3767ZM244.979 20.134H238.115V31.5593H244.766C249.2 31.5593 251.715 29.7688 251.715 25.8467C251.715 21.7114 249.114 20.134 244.979 20.134Z"
        fill="#05203C"
      />
      <path
        d="M203.006 33.6909C203.006 26.9551 207.695 21.1572 215.326 21.1572C223.426 21.1572 227.05 26.8272 227.05 33.0087V35.3109H209.017C209.529 38.764 211.745 40.7677 215.454 40.7677C218.225 40.7677 219.76 39.7445 221.167 37.2719L226.411 40.043C224.194 43.624 220.783 45.9261 215.54 45.9261C207.269 45.9261 203.006 40.7677 203.006 33.6909ZM215.326 26.273C211.873 26.273 209.699 28.1487 209.102 31.474H220.954C220.783 28.5324 219.206 26.273 215.326 26.273Z"
        fill="#05203C"
      />
      <path
        d="M174.118 50.3597L179.831 47.205C181.195 49.4645 182.687 50.6581 185.97 50.6581C189.764 50.6581 191.938 48.356 191.938 43.3681V40.6823C190.446 43.8797 187.845 45.7129 183.966 45.7129C178.296 45.7129 173.905 41.7055 173.905 33.6908C173.905 25.4202 179.106 21.3702 184.392 21.3702C188.144 21.3702 190.574 22.8623 191.981 26.1876V21.626H198.162V42.7713C198.162 51.7239 193.43 56.0297 185.501 56.0297C180.044 56.0297 176.25 54.026 174.118 50.3597ZM192.364 33.5629C192.364 29.2571 190.659 26.3155 186.311 26.3155C182.09 26.3155 180.257 29.3423 180.257 33.5629C180.257 37.826 181.877 40.6823 186.311 40.6823C190.659 40.6823 192.364 37.7834 192.364 33.5629Z"
        fill="#05203C"
      />
      <path
        d="M146.249 45.4998V21.6261H152.431V26.8272C154.051 22.9903 156.907 21.1572 160.573 21.1572C165.817 21.1572 169.185 24.9087 169.185 30.6214V45.4998H162.961V32.3693C162.961 28.5324 161.469 26.3582 157.802 26.3582C154.307 26.3582 152.473 29.044 152.473 33.4351V45.4998H146.249Z"
        fill="#05203C"
      />
      <path
        d="M140.109 21.6261V45.4998H133.927V40.1282C132.307 44.0077 129.493 45.9687 126.04 45.9687C120.498 45.9687 117.599 42.2172 117.599 36.5045V21.6261H123.823V34.7566C123.823 38.5935 125.145 40.7677 128.726 40.7677C132.094 40.7677 133.884 37.7408 133.884 33.4777V21.6261H140.109Z"
        fill="#05203C"
      />
      <path
        d="M100.631 45.9261C92.659 45.9261 88.2253 40.7677 88.2253 33.563C88.2253 26.4435 92.8722 21.1572 100.631 21.1572C108.603 21.1572 113.037 26.5714 113.037 33.563C113.037 40.5545 108.305 45.9261 100.631 45.9261ZM100.631 40.6824C104.98 40.6824 106.685 37.7835 106.685 33.563C106.685 29.2572 104.98 26.3156 100.631 26.3156C96.4106 26.3156 94.5774 29.3424 94.5774 33.563C94.5774 37.8261 96.1974 40.6824 100.631 40.6824Z"
        fill="#05203C"
      />
      <path
        d="M63.7368 45.4998V14.3788H70.0037V39.7446H85.5216V45.4998H63.7368Z"
        fill="#05203C"
      />
    </svg>
  )
}
